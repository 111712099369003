<template>
  <v-text-field v-if="item['editing']" @change="onChange" v-model="item[value]"></v-text-field>
  <span v-else>{{ item[value] }}</span>
</template>

<script>
export default {
  name: 'tableTextField',
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
  },
  methods: {
    onChange(event) {
      this.$emit('set', event);
    },
  },
};
</script>

<style scoped>

</style>
