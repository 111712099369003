<template>
  <v-img :src="url" contain max-width="60" max-height="60"/>
</template>

<script>

import lodash from 'lodash';

export default {
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
  },
  name: 'tableImage',
  data() {
    return {
    };
  },
  computed: {
    url() {
      return lodash.get(this.item, this.value);
    },
  },
};
</script>

<style scoped>

</style>
