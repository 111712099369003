<template>
  <span>
    {{computedLabel}}
  </span>
</template>

<script>

import lodash from 'lodash';

export default {
  name: 'default',
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
  },
  computed: {
    computedLabel() {
      return lodash.get(this.item, this.value);
    },
  },
};
</script>

<style scoped>

</style>
