<template>
  <v-text-field v-if="item['editing']"
                type="number"
                :error="item['error']"
                @change="onChange"
                @input="onInput"
                oninput="if(this.value < 0) this.value = 0;"
                :rules="item['rules'][value]"
                v-model="item[value]"
                :max="item[maxValueProperty]"
                ref="input"
  ></v-text-field>
  <span v-else>{{ item[value] }}</span>
</template>

<script>
export default {
  name: 'tableNumberField',
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
    maxValueProperty: {
      default: null,
    },
  },
  methods: {
    onChange(event) {
      this.$emit('set', event);
    },
    onInput(event) {
      let val = Number.parseInt(event, 10);
      if (this.maxValueProperty) {
        if (val > this.item[this.maxValueProperty]) {
          val = 0;
        }
      }

      this.$nextTick(() => {
        this.item[this.value] = val;
      });
    },
  },
};
</script>

<style scoped>

</style>
