<template>
  <span> {{enums[item[value]]}} </span>
</template>

<script>
export default {
  name: 'enums',
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
    loadedEnums: {
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    enums() {
      return this.$store.getters['entity/getEntity'](this.loadedEnums ? this.loadedEnums : 'enums');
    },
  },
};
</script>

<style scoped>

</style>
