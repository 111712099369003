<template>
  <span v-if="item.hideCheckbox === value"></span>
  <v-checkbox v-model="item[value]"
              v-else
              :readonly="readonly"
              :disabled="item.checkBoxDisabled"
              @click.stop.prevent="handle('check', $event)">
  </v-checkbox>
</template>

<script>
export default {
  name: 'tableCheckbox',
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handle(type, event) {
      this.$emit('set', { type, event });
    },
  },
};
</script>

<style scoped>

</style>
