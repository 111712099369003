<template>
  <span> {{item[value] | format}} </span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'tableDate',
  filters: {
    format(val) {
      if (moment(val).isValid()) {
        return moment(val, 'YYYY-MM-DDTHH:mm:ss.sss[Z]').format('DD-MM-YYYY HH:mm');
      }
      return '';
    },
  },
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
  },
};
</script>

<style scoped>

</style>
