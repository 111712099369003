<template>
<v-container fluid>
  <v-row>
    <v-toolbar flat v-if="!config.hideToolbar">
      <label v-if="config.titleLabel" class="title-label"> {{config.titleLabel}}</label>
      <v-btn v-if="config.add" rounded color="primary" class="mr-3" large
             @click="$emit('handleActions', {type: 'create', item: null})"
      >
        {{ addButtonTitle ? addButtonTitle : 'Добавить' }}
      </v-btn>
      <form :id="random" action="">
        <label>
          <input type="search" v-model="searchStr">
        </label>
        <v-btn v-if="!searchStatus" @click="searchActive(true)" class="fa" fab>
          <v-icon  >
            mdi-magnify
          </v-icon>

        </v-btn>
        <v-btn v-else depressed fab class="fa" text @click.stop="searchActive(false)">
          <v-icon >
            mdi-close
          </v-icon>
        </v-btn>
      </form>
      <v-spacer></v-spacer>
      <slot name="last-slot"/>
    </v-toolbar>
  </v-row>
  <v-data-table :headers="headers"
                :items="currentData"
                hide-default-footer
                :items-per-page="size"
                :page.sync="page"
                @page-count="pageCount = $event"
                class="elevation-2 pt-3 mt-6"
                @click:row="clickRow($event)"
                ref="table">
    <template v-for="el of headers" :slot="'item.' + el.value" slot-scope="{item}">
      <component :is="el.type"
                 :key="el.value"
                 :value="el.value"
                 :item="item"
                 :readonly="el.readonly"
                 :loaded-enums="el.enums"
                 :child="el.child"
                 :page="page"
                 :maxValueProperty="el.maxValueProperty"
                 @set="handleFromChild($event, item)"/>
    </template>
  </v-data-table>
  <div class="pagination" v-if="!config.hidePagination">
    <div class="d-flex align-baseline justify-space-between">
      <span class="mr-2">Строк на странице:</span>
      <v-text-field
          v-model.number="size"
          type="number"
          min="1"
          style="max-width: 50px"
          @keypress="onlyNumber"
          @input.native="onChangePageSize"
      ></v-text-field>
    </div>
    <v-pagination
        v-model="page"
        color="#0B583F"
        class="mt-3"
        :length="pageCount"
        :total-visible="6"
    ></v-pagination>
    <div class="d-flex align-baseline">
      <span class="mr-2">Перейти на:</span>
      <v-text-field
          v-model.number="toPage"
          type="number"
          min="1"
          :max="pageCount"
          style="max-width: 50px"
          class="mr-2"
          @input.native="onChangeToPage"
          @keypress.enter="onChangePage(toPage)"
          @keypress="onlyNumber"
      ></v-text-field>
      <v-btn small :disabled="!toPage" @click="onChangePage(toPage)">OK</v-btn>
    </div>
    <div v-if="!$vuetify.breakpoint.mobile">
      <span class="text--disabled">
        Всего строк: {{ totalElements }}
      </span>
    </div>
  </div>
</v-container>
</template>

<script>
import * as tableComponents from '@/components/crm-table/components/index';
import { escapeStringRegexp } from '@/utils/search';
import lodash from 'lodash';

export default {
  name: 'index',
  props: {
    headers: {
      default: () => [],
    },
    data: {
      default: () => [],
    },
    config: {
      default: () => ({
        add: false,
      }),
    },
    addButtonTitle: {
      type: String,
      default: '',
    },
  },
  components: {
    ...tableComponents,
  },
  data() {
    return {
      currentData: [],
      searchStr: '',
      searchStatus: false,
      random: Math.random(),
      pageCount: 0,
      page: 1,
      size: 10,
      toPage: null,
      timeId: null,
    };
  },
  created() {
    this.initTable();
  },
  watch: {
    searchStr(val) {
      this.search(val);
    },
    data: {
      handler(val) {
        this.currentData = [...val];
      },
      immediate: true,
    },
  },
  computed: {
    enums() {
      return this.$store.getters['entity/getEntity']('enums');
    },
    totalElements() {
      // eslint-disable-next-line no-void
      return this.data ? this.data.length : void 0;
    },
  },
  methods: {
    initTable() {
      if (this.pageCount && parseInt(this.page, 10) > this.pageCount) {
        this.page = 1;
      }
    },
    onlyNumber(event) {
      const { keyCode } = event;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    onChangePageSize(event) {
      if (event.target.value) {
        this.size = parseInt(event.target.value, 10) || 1;
        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.page = 1;
        }, 1000);
      }
    },
    onChangeToPage(event) {
      if (event.target.value) {
        const toPage = parseInt(event.target.value, 10);
        if (toPage > this.pageCount) {
          this.toPage = this.pageCount;
        }
        if (toPage === 0) {
          this.toPage = 1;
        }
      }
    },
    onChangePage(page) {
      this.page = page;
      this.toPage = null;
    },
    handleFromChild(element, item) {
      this.$emit('handleActions', {
        type: element.type,
        event: element.event,
        item,
      });
    },
    clickRow(item) {
      if (!this.config.disableRowClick) {
        this.$emit('handleActions', {
          type: 'info',
          item,
        });
      }
    },
    searchActive(status) {
      const element = document.getElementById(this.random.toString());
      if (status) {
        element.classList.add('active');
      } else if (element.classList.contains('active')) {
        element.classList.remove('active');
      }

      if (!status) {
        this.searchStr = '';
      }
      this.searchStatus = status;
    },
    search(str) {
      const items = [...this.data];
      const cols = this.headers.filter((i) => !['imageUrlList', 'actions'].includes(i.value))
        .map((i) => i.value);
      this.currentData = items.filter((item) => {
        if (!str) return true;
        return str.split(' ')
          .filter((it) => it)
          .slice(0, 10)
          .every((keyword) => {
            if (!keyword) return false;
            const regex = new RegExp(escapeStringRegexp(keyword), 'i');
            return cols.some((col) => {
              let value = lodash.get(item, col);
              // eslint-disable-next-line no-prototype-builtins
              if (this.enums.hasOwnProperty(lodash.get(item, col))) {
                value = this.enums[lodash.get(item, col)];
              }
              return regex.test(value);
            });
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  font-size: 14px;
}
.title-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-right: 30px;
}
form{
  position: relative;
  transition: all 0.2s;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 25px;
  border: 4px solid white;
  padding: 5px;
  color: black;
}

input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;;
  height: 42.5px;
  line-height: 30px;
  outline: 0;
  display: none;
  font-size: 1em;
  border-radius: 20px;
  padding: 0 20px;
  background: white;
  border: 1px solid gray;
}

.fa{
  box-sizing: border-box;
  padding: 10px;
  width: 42.5px;
  height: 42.5px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: #07051a;
  text-align: center;
  font-size: 1.2em;
  transition: all 1s;
}

.active{
  width: 300px;
  cursor: pointer;
}

.active input{
  display: block;
}

.active .fa{
  color: black;
}
</style>
