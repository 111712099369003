<template>
  <span> {{!!item[value] ? "Да" : "Нет"}} </span>
</template>

<script>
export default {
  name: 'tableBool',
  props: {
    value: {
      default: '',
    },
    item: {
      default: () => {},
    },
  },
};
</script>

<style scoped>

</style>
