<template>
  <span> {{computedElement}}</span>
</template>

<script>
export default {
  name: 'dictionary',
  props: {
    value: {
      default: '',
    },
    store: {
      default: () => [],
    },
    item: {
      default: () => {},
    },
  },
  created() {
  },
  computed: {
    computedElement() {
      const store = [...this.store];
      store.push({
        id: 2,
        name: 'name',
      });
      const find = store.find((i) => i.id === this.item[this.value]);
      if (find) {
        return find.name;
      } return 'Not found';
    },
  },
};
</script>

<style scoped>

</style>
