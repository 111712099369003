<template>
  <v-select
    :items="item['items']"
    @change="onChange"
    :disabled="item.disabled"
    v-model="item[value]"
    outlined
    hide-details
    clearable
    class="my-2"
  ></v-select>
</template>

<script>
export default {
  name: 'tableSelect',
  props: {
    item: {
      default: () => {},
    },
    value: {
      default: '',
    },
    loadedEnums: {
      default: '',
    },
  },
  methods: {
    onChange(val) {
      this.$emit('set', { type: 'tableSelect', event: { value: val } });
    },
  },
};
</script>

<style scoped>

</style>
